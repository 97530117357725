<template lang="pug">
div
  page-header(:title='title', :url='url', :titleNew='titleNew')
  grid(:url='urlDataSource', :columns='columns', :grid='{height:500}')
</template>

<script>
import PageHeader from '@/components/PageHeader'
import Grid from '@/components/Grid'

import GridTemplate from '@/mixins/GridTemplate'
import Permission from '@/mixins/Permission'

import statusTemplate from '@/modules/statusTemplate'

export default {
  mixins: [GridTemplate, Permission],
  components: {
    PageHeader,
    Grid
  },
  data () {
    return {
      title: 'Status',
      titleNew: '',
      url: '/admin/status/',
      urlDataSource: `${this.$url}/status`,
      columns: [{
        field: 'nome',
        title: 'Título',
        width: 200
      },
      {
        field: 'cor',
        title: 'Status',
        width: 180,
        template: this.statusTemplate
      },
      {
        field: 'tipo',
        title: 'Tipo',
        width: 120
      },
      {
        field: 'status',
        title: 'Ativo',
        width: 120
      }
      ]
    }
  },
  methods: {
    statusTemplate (e) {
      return statusTemplate(e, 'cor', 'cor')
    },
    verifPermissao () {
      this.titleNew = this.ACTION.c ? 'Novo' : ''
      if (this.ACTION.e) {
        this.columns.push({
          field: 'ativo',
          title: 'Ações',
          width: 105,
          template: this.commandTemplate
        })
      }
    }
  },
  created () {
    this.verifPermissao()
  }
}
</script>
